import { MainContainer } from '@components'

// ASSETS
import { ReactComponent as CircleCheckIcon } from '@assets/icons/circle-check.svg'
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle-close.svg'
// STYLES
import styles from './styles'

type Props = {
  isSuccessful: boolean
  message: string
  showBrand?: boolean
}

export const Result: React.FC<Props> = ({
  isSuccessful,
  message,
  showBrand
}) => (
  <MainContainer showBrand={showBrand}>
    <div className={styles}>
      <div className={`status-container ${isSuccessful ? 'success' : ''}`}>
        {isSuccessful ? (
          <CircleCheckIcon className="icon" />
        ) : (
          <CircleCloseIcon className="icon" />
        )}
      </div>
      <p className="message">{message}</p>
    </div>
  </MainContainer>
)

export default Result
