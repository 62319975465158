import { css } from '@linaria/core'

const styles = css`
  @apply flex flex-col items-center justify-center;

  & .not-found {
    @apply text-6xl text-center font-bold font-courier leading-[0.8] text-n1-secondary-300;
  }

  & .message {
    @apply text-sm tracking-wider text-center font-light;
  }
`

export default styles
