import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MainContainer } from '@components'
import {
  AN_ERROR_OCCURRED,
  PLEASE_CONTACT_OR_TRY_AGAIN
} from '@constants/translationsKeys'

// STYLES
import styles from './styles'

type Props = {
  title?: string
  description?: string
}

const Error: React.FC<Props> = ({ title, description }) => {
  const [showBrand, setShowBrand] = useState<boolean>(false)
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    const locationState = location.state

    if (locationState && locationState.showBrand)
      setShowBrand(locationState.showBrand)
  }, [])

  return (
    <MainContainer showAsAPlugin={false} showBrand={showBrand}>
      <div className={styles}>
        <h1>{title ?? t(AN_ERROR_OCCURRED)}</h1>
        <p>{description ?? t(PLEASE_CONTACT_OR_TRY_AGAIN)}</p>
      </div>
    </MainContainer>
  )
}

export default Error
