import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  AuthenticationModel,
  CommonAuthProcessProps,
  EnrollModel,
  SetupModel
} from '@models'
import { AuthenticationStatus, AuthenticationStep } from '@enums'
import ApiService from '@utils/payer-auth-api'
import AuthenticationApi from '@utils/authentication-api'

const usePaywayAuth = ({
  initialAuthentication,
  showBrand
}: CommonAuthProcessProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showResult, setShowResult] = useState<boolean>(false)
  const [step, setStep] = useState<AuthenticationStep>(AuthenticationStep.Setup)
  const [authentication, setAuthentication] = useState<AuthenticationModel>()
  const [setup, setSetup] = useState<SetupModel>()
  const [enroll, setEnroll] = useState<EnrollModel>()

  const navigate = useNavigate()
  const { id } = useParams()
  const checkedId = id ?? ''

  const handleError = (error?: unknown) => {
    if (error) Sentry.captureException(error)

    navigate('/error', { state: { showBrand } })
  }

  const checkIfNotificationFormIsDisplayed = (
    currentAuthenticationValues: AuthenticationModel
  ) => {
    const externalReturnUrl =
      currentAuthenticationValues?.externalReturnUrl ?? ''
    const isRedirectNotification = externalReturnUrl.trim() !== ''

    if (isRedirectNotification) setStep(AuthenticationStep.Notification)
    else {
      emitEventResult(currentAuthenticationValues)
      setShowResult(true)
    }
  }

  const getSetupPayerParams = async () => {
    setIsLoading(true)
    try {
      const responseSetup = await ApiService.post<SetupModel>(
        `setup/${checkedId}`,
        {}
      )

      if (!responseSetup) {
        handleError()
        return
      }

      setSetup(responseSetup)
      setAuthentication(responseSetup.authentication)

      if (responseSetup.authentication.status !== AuthenticationStatus.PENDING)
        checkIfNotificationFormIsDisplayed(responseSetup.authentication)
      else setStep(AuthenticationStep.Enroll)
    } catch (error) {
      handleError(error)
    }
    setIsLoading(false)
  }

  const checkDeviceDataCollectorResult = async (_: any) => {
    setIsLoading(true)
    try {
      const financialOperationResponse = await ApiService.post<EnrollModel>(
        `enroll/${checkedId}`,
        {
          referenceId: '',
          authenticationId: checkedId
        }
      )

      if (!financialOperationResponse) {
        handleError()
        return
      }

      if (
        financialOperationResponse.authentication.status ===
        AuthenticationStatus.PENDING
      ) {
        setStep(AuthenticationStep.StepUp)
      } else {
        checkIfNotificationFormIsDisplayed(
          financialOperationResponse.authentication
        )
      }

      setEnroll(financialOperationResponse)
      setAuthentication(financialOperationResponse.authentication)
    } catch (error) {
      handleError(error)
    }
    setIsLoading(false)
  }

  const checkAuthenticationResult = async (result: any) => {
    let checkedResult = result ?? {}

    setIsLoading(true)
    try {
      const advancedAuthenticationResponse =
        await ApiService.post<AuthenticationModel>(
          `confirmation/payway/${checkedId}`,
          {
            ...checkedResult
          }
        )

      if (!advancedAuthenticationResponse) {
        handleError()
        return
      }

      checkIfNotificationFormIsDisplayed(advancedAuthenticationResponse)
      setAuthentication(advancedAuthenticationResponse)
    } catch (error) {
      handleError(error)
    }
    setIsLoading(false)
  }

  const emitEventResult = (
    currentAuthenticationValues: AuthenticationModel
  ) => {
    AuthenticationApi.emitAuthenticationResult(currentAuthenticationValues)
  }

  useEffect(() => {
    if (
      initialAuthentication &&
      initialAuthentication.status !== AuthenticationStatus.PENDING
    ) {
      setAuthentication(initialAuthentication)
      setIsLoading(false)
      checkIfNotificationFormIsDisplayed(initialAuthentication)
    } else {
      getSetupPayerParams()
    }
  }, [])

  return {
    isLoading,
    showResult,
    authentication,
    step,
    setup,
    enroll,
    checkDeviceDataCollectorResult,
    checkAuthenticationResult
  }
}

export default usePaywayAuth
