import { css } from '@linaria/core'

const styles = css`
  @apply flex flex-col items-center justify-center w-full relative;

  & .background-loader {
    @apply absolute hidden;

    &.in-front {
      @apply block z-20;
    }
  }

  & .iframe {
    @apply w-full rounded-xl bg-white z-10;
    min-height: 800px;

    @media screen and (orientation: portrait) and (max-height: 750px) {
      min-height: calc(100vh - 1rem);
    }

    @media screen and (orientation: landscape) and (max-height: 899px) {
      min-height: calc(100vh - 1rem);
    }

    @media screen and (orientation: portrait) and (max-height: 900px) {
      min-height: 800px;
    }

    @media screen and (min-height: 901px) {
      min-height: 800px;
    }

    @screen sm {
      @apply w-[80vw];
      min-height: calc(94vh - 5rem);

      @media screen and (orientation: portrait) and (max-height: 750px) {
        min-height: calc(100vh - 1rem);
      }

      @media screen and (orientation: landscape) and (max-height: 899px) {
        min-height: calc(100vh - 1rem);
      }

      @media screen and (min-height: 901px) {
        min-height: 800px;
      }
    }

    @screen md {
      @apply w-[44vw];
      min-height: calc(95vh - 5rem);

      @media screen and (orientation: landscape) and (max-height: 899px) {
        min-height: calc(100vh - 1rem);
      }

      @media screen and (min-height: 901px) {
        min-height: 800px;
      }
    }

    @screen lg {
      @apply w-[36vw];
      min-height: calc(94vh - 5rem);

      @media screen and (orientation: landscape) and (max-height: 899px) {
        min-height: calc(100vh - 2rem);
      }

      @media screen and (min-height: 901px) {
        min-height: 800px;
      }
    }
  }
`

export default styles
