import LoadingSpinner from '../LoadingSpinner'
import MainContainer from '../MainContainer'

// STYLES
import styles from './styles'

type Props = {
  message: string
  description: string
  showBrand?: boolean
}

const Loading: React.FC<Props> = ({ message, description, showBrand }) => (
  <MainContainer showBrand={showBrand}>
    <div className={styles}>
      <LoadingSpinner size={56} />
      <div className="messages">
        <p>{message}</p>
        <p>{description}</p>
      </div>
    </div>
  </MainContainer>
)

export default Loading
