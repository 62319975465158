import { useTranslation } from 'react-i18next'
import { Loading, Result } from '@components'
import {
  AUTHENTICATION_FAILED,
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION,
  TRY_AGAIN,
  UNVERIFIED_AUTHENTICATION
} from '@constants/translationsKeys'
import { CommonAuthProcessProps } from '@models'
import { AuthenticationStatus, AuthenticationStep } from '@enums'
import { NotificationForm } from '@forms'
import { getAuthenticationStatusString } from '@utils/authentication-helpers'
import { useCybersourceAuth } from '../../hooks'
import { SetupForm, StepUpForm } from './forms'

const CybersourceAuth: React.FC<CommonAuthProcessProps> = ({
  showBrand,
  initialAuthentication
}) => {
  const { t } = useTranslation()
  const {
    step,
    setup,
    enroll,
    authentication,
    emitEventResult,
    generateEnrollPayer,
    verifyAuthenticationResult
  } = useCybersourceAuth({ showBrand, initialAuthentication })

  const externalReturnUrl = authentication?.externalReturnUrl ?? ''
  const isRedirectNotification = externalReturnUrl.trim() !== ''

  const LoadingComponent = (
    <Loading
      showBrand={showBrand}
      message={t(ONE_MOMENT, { extra: '...' })}
      description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
    />
  )

  switch (step) {
    case AuthenticationStep.Setup:
      return (
        <>
          {LoadingComponent}
          <SetupForm
            collectionUrl={setup?.collectionUrl ?? ''}
            accessToken={setup?.accessToken ?? ''}
            onComplete={generateEnrollPayer}
          />
        </>
      )
    case AuthenticationStep.StepUp:
      return (
        <StepUpForm
          stepUpUrl={enroll?.stepUpUrl ?? ''}
          accessToken={enroll?.accessToken ?? ''}
          onComplete={() =>
            verifyAuthenticationResult({
              unverifiedAuthMessage: t(UNVERIFIED_AUTHENTICATION, {
                extra: t(TRY_AGAIN).toLowerCase()
              }),
              authFailedMessage: t(AUTHENTICATION_FAILED, {
                extra: t(TRY_AGAIN).toLowerCase()
              })
            })
          }
        />
      )
    case AuthenticationStep.Notification:
      if (!isRedirectNotification) emitEventResult()

      return isRedirectNotification ? (
        <>
          {LoadingComponent}
          <NotificationForm
            status={getAuthenticationStatusString(authentication?.status)}
            message={authentication?.statusMsg ?? ''}
            authenticationId={authentication!.authenticationId}
            orderId={authentication!.orderId}
            orderAmount={authentication!.orderAmount}
            redirectUrl={authentication!.externalReturnUrl}
          />
        </>
      ) : (
        <Result
          isSuccessful={authentication!.status === AuthenticationStatus.SUCCESS}
          showBrand={showBrand}
          message={authentication?.statusMsg ?? ''}
        />
      )
    case AuthenticationStep.Loading:
    default:
      return LoadingComponent
  }
}

export default CybersourceAuth
