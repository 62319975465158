import { useTranslation } from 'react-i18next'
import { CommonAuthProcessProps } from '@models'
import { AuthenticationStatus } from '@enums'
import { Loading, Result } from '@components'
import { NotificationForm } from '@forms'
import {
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION
} from '@constants/translationsKeys'
import { getAuthenticationStatusString } from '@utils/authentication-helpers'
import { usePowertranzAuth } from '../../hooks'
import { SetupIframe } from './components'

const PowertranzAuth: React.FC<CommonAuthProcessProps> = ({
  showBrand,
  initialAuthentication
}) => {
  const { t } = useTranslation()
  const {
    isLoading,
    authentication,
    setup,
    showNotificationForm,
    verifyAuthenticationResult
  } = usePowertranzAuth({
    showBrand,
    initialAuthentication
  })

  const LoadingComponent = (
    <Loading
      showBrand={showBrand}
      message={t(ONE_MOMENT, { extra: '...' })}
      description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
    />
  )

  if (isLoading) return LoadingComponent

  if (authentication?.status === AuthenticationStatus.PENDING)
    return (
      <SetupIframe
        setupResult={setup}
        onComplete={verifyAuthenticationResult}
      />
    )

  if (showNotificationForm)
    return (
      <>
        {LoadingComponent}
        <NotificationForm
          status={getAuthenticationStatusString(authentication?.status)}
          message={authentication?.statusMsg!}
          authenticationId={authentication!.authenticationId}
          orderId={authentication!.orderId}
          orderAmount={authentication!.orderAmount}
          redirectUrl={authentication!.externalReturnUrl}
        />
      </>
    )

  return (
    <Result
      isSuccessful={authentication?.status === AuthenticationStatus.SUCCESS}
      showBrand={showBrand}
      message={authentication?.statusMsg ?? ''}
    />
  )
}

export default PowertranzAuth
