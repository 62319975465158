import { useEffect } from 'react'

const {
  // TODO: Change the name of the environment variable
  P3F_CYBERSOURCE_PAYER_SETUP_ALLOWED_ORIGINS: PAYER_SETUP_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionUrl: string
  accessToken: string
  onComplete: () => Promise<void>
}

export const SetupForm: React.FC<Props> = ({
  collectionUrl,
  accessToken,
  onComplete
}) => {
  useEffect(() => {
    const formCollection = document.getElementById(
      'cardinal_collection_form'
    ) as HTMLFormElement

    formCollection.submit()
    window.addEventListener(
      'message',
      (event) => {
        const validOrigins = PAYER_SETUP_ALLOWED_ORIGINS.split(',')

        if (validOrigins.includes(event.origin)) {
          const content = JSON.parse(event.data as string)
          const type = content['MessageType']
          if (type === 'profile.completed') onComplete()
        }
      },
      false
    )
  }, [])

  return (
    <>
      <iframe
        id="cardinal_collection_iframe"
        name="collectionIframe"
        className="hidden"
      />
      <form
        id="cardinal_collection_form"
        method="POST"
        target="collectionIframe"
        action={collectionUrl}
      >
        <input
          type="hidden"
          id="cardinal_collection_form_input"
          name="JWT"
          value={accessToken}
        />
      </form>
    </>
  )
}

export default SetupForm
