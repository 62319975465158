import { css } from '@linaria/core'

const styles = css`
  @apply flex justify-center w-full h-full px-8 py-16;

  iframe {
    @apply w-full h-full;
  }
`

export default styles
