import LoadingSpinner from '../LoadingSpinner'
import MainContainer from '../MainContainer'

import styles from './styles'

const FullLoading: React.FC = () => (
  <MainContainer showAsAPlugin={false} showBrand={false}>
    <div className={styles}>
      <LoadingSpinner />
    </div>
  </MainContainer>
)

export default FullLoading
