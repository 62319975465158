import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  AuthenticationModel,
  CommonAuthProcessProps,
  SetupModel
} from '@models'
import { AuthenticationStatus } from '@enums'
import ApiService from '@utils/payer-auth-api'
import AuthenticationApi from '@utils/authentication-api'

const usePowertranzAuth = ({
  showBrand,
  initialAuthentication
}: CommonAuthProcessProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [setup, setSetup] = useState<SetupModel>()
  const [authentication, setAuthentication] = useState<AuthenticationModel>()
  const [showNotificationForm, setShowNotificationForm] =
    useState<boolean>(false)

  const navigate = useNavigate()
  const { id } = useParams()
  const checkedId = id ?? ''

  const handleError = (error?: unknown) => {
    if (error) Sentry.captureException(error)

    navigate('/error', { state: { showBrand } })
  }

  const checkIfNotificationFormIsDisplayed = (
    currentAuthenticationValues: AuthenticationModel
  ) => {
    const externalReturnUrl =
      currentAuthenticationValues?.externalReturnUrl ?? ''
    const isRedirectNotification = externalReturnUrl.trim() !== ''

    if (isRedirectNotification) setShowNotificationForm(true)
    else emitEventResult(currentAuthenticationValues)
  }

  const getSetupPayerParams = async () => {
    setIsLoading(true)
    try {
      const responseSetup = await ApiService.post<SetupModel>(
        `setup/${checkedId}`,
        {}
      )

      if (!responseSetup) {
        handleError()
        return
      }

      setSetup(responseSetup)
      setAuthentication(responseSetup.authentication)

      if (responseSetup.authentication.status !== AuthenticationStatus.PENDING)
        checkIfNotificationFormIsDisplayed(responseSetup.authentication)
    } catch (error) {
      handleError(error)
    }
    setIsLoading(false)
  }

  const verifyAuthenticationResult = async () => {
    setIsLoading(true)
    try {
      const responseAuthentication = await AuthenticationApi.get(checkedId)

      if (!responseAuthentication) {
        handleError()
        return
      }

      setAuthentication(responseAuthentication)
      checkIfNotificationFormIsDisplayed(responseAuthentication)
    } catch (error) {
      handleError(error)
    }
    setIsLoading(false)
  }

  const emitEventResult = (
    currentAuthenticationValues: AuthenticationModel
  ) => {
    AuthenticationApi.emitAuthenticationResult(currentAuthenticationValues)
  }

  useEffect(() => {
    if (
      initialAuthentication &&
      initialAuthentication.status !== AuthenticationStatus.PENDING
    ) {
      checkIfNotificationFormIsDisplayed(initialAuthentication)
      setAuthentication(initialAuthentication)
      setIsLoading(false)
    } else {
      getSetupPayerParams()
    }
  }, [])

  return {
    isLoading,
    authentication,
    setup,
    showNotificationForm,
    verifyAuthenticationResult
  }
}

export default usePowertranzAuth
