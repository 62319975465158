import { css } from '@linaria/core'

const styles = css`
  @apply flex flex-col items-center justify-center;

  & .status-container {
    @apply p-2 rounded-2xl bg-n1-error-500 shadow-md;

    &.success {
      @apply bg-n1-success-500;
    }

    & .icon {
      @apply w-12 h-12;

      & > path {
        @apply fill-white;
      }
    }
  }

  & .message {
    @apply text-sm tracking-wider text-center font-light pt-5;
  }
`

export default styles
