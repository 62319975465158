import { useEffect, useRef } from 'react'
import { SetupModel } from '@models'
import { MainContainer } from '@components'

import styles from './styles'

const {
  P3F_POWERTRANZ_PAYER_SETUP_ALLOWED_ORIGINS:
    POWERTRANZ_PAYER_SETUP_ALLOWED_ORIGINS
} = p3fenv

type props = {
  setupResult?: SetupModel
  onComplete: () => void
}

const SetupIframe: React.FC<props> = ({ setupResult, onComplete }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current
      const doc = iframe.contentWindow?.document
      doc?.open()
      doc?.write(setupResult?.htmlContent ?? '')
      doc?.close()
    }
  }, [setupResult])

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        const validOrigins = POWERTRANZ_PAYER_SETUP_ALLOWED_ORIGINS.split(',')

        if (validOrigins.includes(event.origin)) {
          const content = JSON.parse(event.data as string)
          const type = content['MessageType']
          if (type === 'authentication.verified') onComplete()
        }
      },
      false
    )
  }, [])

  return (
    <MainContainer showBrand={false} showAsAPlugin={false}>
      <div className={styles}>
        <iframe id="threedsIframe" ref={iframeRef} />
      </div>
    </MainContainer>
  )
}

export default SetupIframe
