// ASSETS
import { ReactComponent as Circles } from '@assets/icons/circles.svg'

// STYLES
import styles from './styles'

type Props = {
  size?: number
}

const LoadingSpinner: React.FC<Props> = ({ size = 40 }) => (
  <Circles className={styles} width={size} height={size} />
)

export default LoadingSpinner
