import { useTranslation } from 'react-i18next'
import { AuthenticationProvider } from '@enums'
import { Loading } from '@components'
import {
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION
} from '@constants/translationsKeys'
import { useInitialConfig, useURLQueryParams } from './hooks'
import { CybersourceAuth, PaywayAuth, PowertranzAuth } from './components'

export const Authentication: React.FC = () => {
  const { t } = useTranslation()
  const { showBrand } = useURLQueryParams()
  const { authentication, isLoading } = useInitialConfig({ showBrand })

  if (isLoading)
    return (
      <Loading
        showBrand={showBrand}
        message={t(ONE_MOMENT, { extra: '...' })}
        description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
      />
    )

  switch (authentication?.provider) {
    case AuthenticationProvider.POWERTRANZ:
      return (
        <PowertranzAuth
          showBrand={showBrand}
          initialAuthentication={authentication}
        />
      )
    case AuthenticationProvider.PAYWAY:
      return (
        <PaywayAuth
          showBrand={showBrand}
          initialAuthentication={authentication}
        />
      )
    case AuthenticationProvider.CYBERSOURCE:
    case AuthenticationProvider.SANDBOX:
    default:
      return (
        <CybersourceAuth
          showBrand={showBrand}
          initialAuthentication={authentication}
        />
      )
  }
}
export default Authentication
