import { css } from '@linaria/core'

const styles = css`
  animation: turn 1.5s linear infinite;

  circle {
    animation: stroke-fill 7s linear infinite;
    fill: none;
    stroke-dasharray: 63;
    stroke-dashoffset: 63;
    stroke-linecap: round;
    stroke-width: 3;

    &:nth-child(1) {
      @apply stroke-n1-primary-500;
    }
    &:nth-child(2) {
      @apply stroke-custom-loader-200;
      animation-delay: 2s;
    }
    &:nth-child(3) {
      @apply stroke-custom-loader-100;
      animation-delay: 4s;
    }
  }

  @keyframes turn {
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }

  @keyframes stroke-fill {
    15% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 63;
    }
  }
`

export default styles
