import { css } from '@linaria/core'

const styles = css`
  @apply flex flex-col items-center justify-center w-full;

  h1 {
    @apply text-n1-error-500 text-2xl text-center font-bold mb-5;

    @screen md {
      @apply text-4xl;
    }
  }

  p {
    @apply text-sm tracking-wider text-center font-light;
  }
`

export default styles
