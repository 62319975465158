import * as Sentry from '@sentry/react'

const { P3F_PAYER_AUTHENTICATION_URL: PAYER_AUTHENTICATION_URL } = p3fenv

class ApiService {
  static async get<T>(path: string): Promise<T | undefined> {
    try {
      const checkedURL = PAYER_AUTHENTICATION_URL + '/' + path
      const response = await fetch(checkedURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP Error returned by API: GET-> ${response.status}`)
      }

      return response.json() as T
    } catch (error) {
      Sentry.captureException(error)
      return undefined
    }
  }

  static async post<T>(path: string, data: any): Promise<T | undefined> {
    try {
      const checkedURL = PAYER_AUTHENTICATION_URL + '/' + path
      const response = await fetch(checkedURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      if (!response.ok) {
        throw new Error(`HTTP Error returned by API: POST-> ${response.status}`)
      }

      return response.json() as T
    } catch (error) {
      Sentry.captureException(error)
      return undefined
    }
  }
}

export default ApiService
