import { useEffect } from 'react'

const {
  // TODO: Change the name of the environment variable
  P3F_CYBERSOURCE_PAYER_SETUP_ALLOWED_ORIGINS: PAYER_SETUP_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionURL: string
  accessToken: string
  onComplete: (result: any) => Promise<void>
}

const DeviceDataCollectorForm: React.FC<Props> = ({
  accessToken,
  collectionURL,
  onComplete
}) => {
  useEffect(() => {
    const formCollection = document.getElementById(
      'formFor3ds'
    ) as HTMLFormElement
    formCollection.submit()

    window.addEventListener(
      'message',
      (event) => {
        const validOrigins = PAYER_SETUP_ALLOWED_ORIGINS.split(',')

        if (validOrigins.includes(event.origin)) {
          const result = JSON.parse(event.data as string)

          onComplete(result)
        }
      },
      false
    )
  }, [])

  return (
    <>
      <iframe id="iframe3ds" name="ddc-iframe" className="hidden" />
      <form
        id="formFor3ds"
        method="POST"
        target="ddc-iframe"
        action={collectionURL}
      >
        <input type="hidden" id="jwtFor3ds" name="JWT" value={accessToken} />
      </form>
    </>
  )
}

export default DeviceDataCollectorForm
