import { css } from '@linaria/core'

const styles = css`
  @apply relative flex flex-col h-screen items-center justify-center p-2;

  @screen md {
    @apply p-10;

    @media screen and (orientation: landscape) and (max-height: 899px) {
      @apply py-0;
    }
  }

  background-image: url('../../assets/icons/sun.svg'),
    url('../../assets/icons/moon.svg');
  background-repeat: no-repeat, no-repeat;
  background-position:
    -70px -100px,
    calc(100% + 100px) calc(100% + 100px);
  background-size:
    250px 250px,
    350px 350px;

  &.hide-brand {
    & .plugin-container {
      @apply gap-0;
    }

    & .header {
      @apply h-0 opacity-0 p-0 border-none transition-all !important;

      & .main-brand-icon {
        @apply h-0 opacity-0;
      }
    }
  }

  & > .main-brand-icon {
    @apply mb-6 opacity-100 transition-all;
  }

  & .plugin-container {
    @apply flex flex-col items-center p-4 shadow rounded-2xl 
            border border-n1-secondary-100 max-w-none gap-4 bg-white;

    @screen sm {
      @apply max-w-[50vw];
    }

    @screen md {
      @apply max-w-[40vw];
    }

    @screen lg {
      @apply max-w-[30vw];
    }

    & .header,
    & .content {
      @apply flex items-center justify-center w-full rounded-lg 
              border border-n1-secondary-100 p-4;
    }

    & .header {
      @apply shadow-inner bg-n1-secondary-100 opacity-100 transition-all;
    }

    & .content {
      @apply min-h-[40vh];
    }
  }
`

export default styles
