import { css } from '@linaria/core'

const styles = css`
  @apply flex flex-col items-center justify-center;

  & .messages {
    @apply mt-5;

    & > p {
      @apply text-sm tracking-wider text-center font-light;
    }
  }
`

export default styles
