import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Languages } from '@enums'

const { P3F_USE_LANGUAGE_DETECTION: USE_LANGUAGE_DETECTION } = p3fenv

const useURLQueryParams = () => {
  const [showBrand, setShowBrand] = useState<boolean>(true)

  const { i18n } = useTranslation()
  const location = useLocation()

  const setQueryParamsRestrictions = () => {
    const useLanguageDetection = USE_LANGUAGE_DETECTION === 'true'
    const queryParams = new URLSearchParams(location.search)
    const showBrandingParam = queryParams.get('showBranding')
    const langParam = queryParams.get('lang')

    if (showBrandingParam) {
      setShowBrand(showBrandingParam.toLowerCase() === 'true')
    }

    if (useLanguageDetection && langParam) {
      let lang = langParam.toLowerCase() === 'en' ? Languages.EN : Languages.ES

      i18n.changeLanguage(lang)
    }
  }

  useEffect(() => {
    setQueryParamsRestrictions()
  }, [])

  return { showBrand }
}

export default useURLQueryParams
