import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  AuthenticationStatus,
  AuthenticationProvider,
  AuthenticationStep
} from '@enums'
import {
  AuthenticationModel,
  CommonAuthProcessProps,
  EnrollModel,
  SetupModel
} from '@models'
import ApiService from '@utils/payer-auth-api'
import AuthenticationApi from '@utils/authentication-api'

type VerifyAuthResultProps = {
  unverifiedAuthMessage: string
  authFailedMessage: string
}

const useCybersourceAuth = ({
  showBrand,
  initialAuthentication
}: CommonAuthProcessProps) => {
  const [step, setStep] = useState<AuthenticationStep>(
    AuthenticationStep.Loading
  )
  const [setup, setSetup] = useState<SetupModel>()
  const [enroll, setEnroll] = useState<EnrollModel>()
  const [authentication, setAuthentication] = useState<AuthenticationModel>()

  const navigate = useNavigate()
  const { id } = useParams()
  const checkedId = id ?? ''

  const handleError = (error?: unknown) => {
    if (error) Sentry.captureException(error)

    navigate('/error', { state: { showBrand } })
  }

  const getSetupPayerParams = async () => {
    try {
      const responseSetup = await ApiService.post<SetupModel>(
        `setup/${checkedId}`,
        {}
      )

      if (!responseSetup) {
        handleError()
        return
      }

      const nextStep =
        responseSetup.authentication?.status === AuthenticationStatus.PENDING
          ? AuthenticationStep.Setup
          : AuthenticationStep.Notification

      setSetup(responseSetup)
      setAuthentication(responseSetup.authentication)
      setStep(nextStep)
    } catch (error) {
      handleError(error)
    }
  }

  const generateEnrollPayer = async () => {
    try {
      const responseEnroll = await ApiService.post<EnrollModel>(
        `enroll/${checkedId}`,
        {
          referenceId: setup?.referenceId,
          authenticationId: id
        }
      )

      if (!responseEnroll) {
        handleError()
        return
      }

      const nextStep =
        responseEnroll.authentication.status === AuthenticationStatus.PENDING
          ? AuthenticationStep.StepUp
          : AuthenticationStep.Notification

      setEnroll(responseEnroll)
      setAuthentication(responseEnroll.authentication)
      setStep(nextStep)
    } catch (error) {
      setStep(AuthenticationStep.Notification)
      Sentry.captureException(error)
    }
  }

  const verifyAuthenticationResult = async ({
    unverifiedAuthMessage,
    authFailedMessage
  }: VerifyAuthResultProps) => {
    let defaultFailedAuthentication: AuthenticationModel = {
      authenticationId: '',
      externalReturnUrl: '',
      orderAmount: 0.0,
      orderId: '',
      payerEmail: '',
      provider: AuthenticationProvider.CYBERSOURCE,
      status: AuthenticationStatus.FAILED,
      statusMsg: ''
    }

    try {
      const responseAuthentication = await AuthenticationApi.get(checkedId)

      if (responseAuthentication) {
        setAuthentication(responseAuthentication)
      } else {
        defaultFailedAuthentication = {
          ...defaultFailedAuthentication,
          statusMsg: unverifiedAuthMessage
        }
        setAuthentication(defaultFailedAuthentication)
      }

      setStep(AuthenticationStep.Notification)
    } catch (error) {
      defaultFailedAuthentication = {
        ...defaultFailedAuthentication,
        statusMsg: authFailedMessage
      }

      setAuthentication(defaultFailedAuthentication)
      setStep(AuthenticationStep.Notification)
      Sentry.captureException(error)
    }
  }

  const emitEventResult = () => {
    AuthenticationApi.emitAuthenticationResult(authentication)
  }

  useEffect(() => {
    if (
      initialAuthentication &&
      initialAuthentication.status !== AuthenticationStatus.PENDING
    ) {
      setAuthentication(initialAuthentication)
      setStep(AuthenticationStep.Notification)
    } else {
      getSetupPayerParams()
    }
  }, [])

  return {
    step,
    setup,
    enroll,
    authentication,
    generateEnrollPayer,
    emitEventResult,
    verifyAuthenticationResult
  }
}

export default useCybersourceAuth
