import { useEffect } from 'react'

type Props = {
  status: string
  message: string
  authenticationId: string
  orderId: string
  orderAmount: number
  redirectUrl: string | undefined
}

export const NotificationForm: React.FC<Props> = ({
  status,
  message,
  authenticationId,
  orderId,
  orderAmount,
  redirectUrl
}) => {
  useEffect(() => {
    const notificationForm = document.getElementById(
      'notification_form'
    ) as HTMLFormElement

    notificationForm.submit()
  }, [])

  return (
    <form id="notification_form" method="POST" action={redirectUrl}>
      <input type="hidden" name="status" value={status} />
      <input type="hidden" name="message" value={message} />
      <input type="hidden" name="authenticationId" value={authenticationId} />
      <input type="hidden" name="orderId" value={orderId} />
      <input type="hidden" name="orderAmount" value={orderAmount} />
    </form>
  )
}

export default NotificationForm
