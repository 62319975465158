import { useTranslation } from 'react-i18next'
import { MainContainer } from '@components'
import { PAGE_NOT_FOUND } from '../../constants/translationsKeys'

// STYLES
import styles from './styles'

const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <MainContainer showAsAPlugin={false} showBrand={true}>
      <div className={styles}>
        <span className="not-found">404</span>
        <p className="message">{t(PAGE_NOT_FOUND)}</p>
      </div>
    </MainContainer>
  )
}

export default NotFound
