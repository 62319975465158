import { useEffect } from 'react'
import { MainContainer } from '@components'

// STYLES
import styles from './styles'

const {
  P3F_PAYWAY_PAYER_STEPUP_ALLOWED_ORIGINS: PAYER_STEPUP_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionURL: string
  accessToken: string
  onComplete: (result: any) => Promise<void>
}

const AdvancedAuthenticationForm: React.FC<Props> = ({
  accessToken,
  collectionURL,
  onComplete
}) => {
  useEffect(() => {
    const formCollection = document.getElementById(
      'formFor3ds'
    ) as HTMLFormElement
    formCollection.submit()

    window.addEventListener(
      'message',
      (event) => {
        const validOrigins = PAYER_STEPUP_ALLOWED_ORIGINS.split(',')

        if (validOrigins.includes(event.origin)) {
          const result = JSON.parse(event.data as string)

          onComplete(result)
        }
      },
      false
    )
  }, [])

  return (
    <MainContainer showBrand={false} showAsAPlugin={false}>
      <div id="iframe3dsOverlay" className={styles}>
        <iframe className="iframe" id="iframe3ds" name="iframe3ds" />
        <form
          id="formFor3ds"
          target="iframe3ds"
          method="POST"
          action={collectionURL}
        >
          <input type="hidden" id="jwtFor3ds" name="JWT" value={accessToken} />
        </form>
      </div>
    </MainContainer>
  )
}

export default AdvancedAuthenticationForm
