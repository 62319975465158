import i18n from 'i18next'
import i18nBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { Languages } from './enums'

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: Languages.ES,
    lng: Languages.ES,
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/i18n/{{lng}}.json'
    }
  })

export default i18n
