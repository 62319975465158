import { useEffect, useState } from 'react'
import { MainContainer, LoadingSpinner } from '@components'

// STYLES
import styles from './styles'

const { P3F_PAYER_STEPUP_ALLOWED_ORIGINS: PAYER_STEPUP_ALLOWED_ORIGINS } =
  p3fenv

type Props = {
  stepUpUrl: string
  accessToken: string
  onComplete: () => Promise<void>
}

const StepUpForm: React.FC<Props> = ({
  stepUpUrl,
  accessToken,
  onComplete
}) => {
  const [iframeLoaderCounter, setIFrameLoaderCounter] = useState<number>(0)

  useEffect(() => {
    const formCollection = document.getElementById(
      'step-up-form'
    ) as HTMLFormElement

    formCollection.submit()
    window.addEventListener(
      'message',
      (event) => {
        const validOrigins = PAYER_STEPUP_ALLOWED_ORIGINS.split(',')

        if (validOrigins.includes(event.origin)) {
          const content = JSON.parse(event.data as string)
          const type = content['MessageType']

          if (type === 'authentication.verified') onComplete()
        }
      },
      false
    )
  }, [])

  const iframeLoaderCounterHandler = () => {
    setIFrameLoaderCounter(iframeLoaderCounter + 1)
  }

  return (
    <MainContainer showBrand={false} showAsAPlugin={false}>
      <div className={styles}>
        <div
          className={`background-loader ${
            iframeLoaderCounter > 2 ? 'in-front' : ''
          }`}
        >
          <LoadingSpinner size={56} />
        </div>
        <iframe
          id="step-up-iframe"
          name="step-up-iframe"
          className="iframe"
          ref={(iframe) => {
            if (iframe) {
              iframe.addEventListener('load', iframeLoaderCounterHandler)
            }
          }}
        />
        <form
          id="step-up-form"
          target="step-up-iframe"
          method="post"
          action={stepUpUrl}
        >
          <input type="hidden" name="JWT" value={accessToken} />
        </form>
      </div>
    </MainContainer>
  )
}
export default StepUpForm
